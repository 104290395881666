/* eslint-disable */
function Player() {
  // Get a handle to the blobDaTela
  const blobDaTela = document.getElementById('blobDaTela'),
    blobDaCam = document.getElementById('blobDaCam'),
    btnPlayPause = document.getElementById('btnPlayPause'),
    btnMute = document.getElementById('btnMute'),
    btnStop = document.getElementById('btnStop'),
    btnReplay = document.getElementById('btnReplay'),
    btnFullScreen = document.getElementById('btnFullScreen'),
    progressBar = document.getElementById('progress-bar'),
    volumeBar = document.getElementById('volume-bar')

  // Update the video volume
  volumeBar.addEventListener('change', (evt) => {
    blobDaTela.volume = evt.target.value
    blobDaCam.volume = evt.target.value
  })

  // document.getElementById('btnFullScreen').disabled = true;

  // Add a listener for the timeupdate event so we can update the progress bar
  blobDaTela.addEventListener('timeupdate', updateProgressBar, false)

  // Add a listener for the play and pause events so the buttons state can be updated
  blobDaTela.addEventListener(
    'play',
    () => {
      // Change the button to be a pause button
      changeButtonType(
        btnPlayPause,
        '<i class="fa-regular fa-circle-pause"></i>',
        'btn btn-info'
      )
    },
    false
  )
  btnPlayPause.addEventListener('click', playPauseVideo)
  btnStop.addEventListener('click', stopVideo)
  btnReplay.addEventListener('click', replayVideo)
  btnFullScreen.addEventListener('click', toggleFullScreen)
  btnMute.addEventListener('click', muteVolume)

  blobDaTela.addEventListener(
    'pause',
    () => {
      // Change the button to be a play button
      changeButtonType(
        btnPlayPause,
        '<i class="fa-regular fa-circle-play"></i>',
        'btn btn-info'
      )
    },
    false
  )

  blobDaTela.addEventListener(
    'volumechange',
    (e) => {
      // Update the button to be mute/unmute
      if (blobDaTela.muted) changeButtonType(btnMute, 'unmute')
      else changeButtonType(btnMute, 'mute')
    },
    false
  )

  blobDaTela.addEventListener(
    'ended',
    function () {
      this.pause()
    },
    false
  )

  /// /////////////////////

  // Add a listener for the timeupdate event so we can update the progress bar
  blobDaCam.addEventListener('timeupdate', updateProgressBar, false)

  // Add a listener for the play and pause events so the buttons state can be updated
  blobDaCam.addEventListener(
    'play',
    () => {
      // Change the button to be a pause button
      changeButtonType(
        btnPlayPause,
        '<i class="fa-regular fa-circle-pause"></i>',
        'btn btn-info'
      )
    },
    false
  )

  blobDaCam.addEventListener(
    'pause',
    () => {
      // Change the button to be a play button
      changeButtonType(
        btnPlayPause,
        '<i class="fa-regular fa-circle-play"></i>',
        'btn btn-info'
      )
    },
    false
  )

  blobDaCam.addEventListener(
    'volumechange',
    (e) => {
      // Update the button to be mute/unmute
      if (blobDaCam.muted) changeButtonType(btnMute, 'unmute')
      else changeButtonType(btnMute, 'mute')
    },
    false
  )

  blobDaCam.addEventListener(
    'ended',
    function () {
      this.pause()
    },
    false
  )

  /// /////////////////////

  progressBar.addEventListener('click', seek)

  function seek(e) {
    const percent = e.offsetX / this.offsetWidth
    blobDaTela.currentTime = percent * blobDaTela.duration
    blobDaCam.currentTime = percent * blobDaCam.duration
    e.target.value = Math.floor(percent / 100)
    e.target.innerHTML = `${progressBar.value}% played`
  }

  function playPauseVideo() {
    if (blobDaTela.paused || blobDaTela.ended) {
      // Change the button to a pause button
      changeButtonType(btnPlayPause, 'pause')
      blobDaTela.play()
      blobDaCam.play()
    } else {
      // Change the button to a play button
      changeButtonType(btnPlayPause, 'play')
      blobDaTela.pause()
      blobDaCam.pause()
    }
  }

  // Stop the current media from playing, and return it to the start position
  function stopVideo() {
    blobDaTela.pause()
    blobDaCam.pause()
    if (blobDaTela.currentTime && blobDaCam.currentTime) {
      blobDaTela.currentTime = 0
      blobDaCam.currentTime = 0
    }
  }

  // Toggles the media blobDaTela's mute and unmute status
  function muteVolume() {
    if (blobDaTela.muted) {
      // Change the button to a mute button
      changeButtonType(btnMute, 'mute')
      blobDaTela.muted = false
      blobDaCam.muted = false
    } else {
      // Change the button to an unmute button
      changeButtonType(btnMute, 'unmute')
      blobDaTela.muted = true
      blobDaCam.muted = true
    }
  }

  // Replays the media currently loaded in the blobDaTela
  function replayVideo() {
    resetblobDaTela()
    blobDaTela.play()
    blobDaCam.play()
  }

  // Update the progress bar
  function updateProgressBar() {
    // Work out how much of the media has played via the duration and currentTime parameters
    const percentage = Math.floor(
      (100 / blobDaTela.duration) * blobDaTela.currentTime
    )
    // Update the progress bar's value
    progressBar.value = percentage
    // Update the progress bar's text (for browsers that don't support the progress element)
    progressBar.innerHTML = `${percentage}% played`
  }

  // Updates a button's title, innerHTML and CSS class
  function changeButtonType(btn, value, classname) {
    // btn.title     = value;
    btn.innerHTML = value
    btn.className = classname
  }

  function resetblobDaTela() {
    progressBar.value = 0
    // Move the media back to the start
    blobDaTela.currentTime = 0
    blobDaCam.currentTime = 0
    // Set the play/pause button to 'play'
    changeButtonType(btnPlayPause, 'play')
  }

  function exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  }

  function toggleFullScreen(elem) {
    elem = elem || document.documentElement

    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen()
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  }

  /*
function toggleFullScreen() {
  //var blobDaTela = document.getElementById("blobDaTela");

  if (blobDaTela.requestFullscreen)
      if (document.fullScreenElement) {
          document.cancelFullScreen();
      } else {
          blobDaTela.requestFullscreen();
      }
      else if (blobDaTela.msRequestFullscreen)
      if (document.msFullscreenElement) {
          document.msExitFullscreen();
      } else {
          blobDaTela.msRequestFullscreen();
      }
      else if (blobDaTela.mozRequestFullScreen)
      if (document.mozFullScreenElement) {
          document.mozCancelFullScreen();
      } else {
          blobDaTela.mozRequestFullScreen();
      }
      else if (blobDaTela.webkitRequestFullscreen)
      if (document.webkitFullscreenElement) {
          document.webkitCancelFullScreen();
      } else {
          blobDaTela.webkitRequestFullscreen();
      }
  else {
      alert("Fullscreen API is not supported");

  }
}
*/
}

export default Player
